@font-face {
    font-family: 'Poppins ExtraBold';
    src: url('fonts/Poppins-ExtraBold.woff2') format('woff2'),
         url('fonts/Poppins-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
  font-family: 'Poppins Bold';
  src: url('fonts/Poppins-Bold.woff2') format('woff2'),
       url('fonts/Poppins-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'Poppins Regular';
  src: url('fonts/Poppins-Regular.woff2') format('woff2'),
        url('fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
font-family: 'Poppins Medium';
src: url('fonts/Poppins-Medium.woff2') format('woff2'),
      url('fonts/Poppins-Medium.woff') format('woff');
font-weight: normal;
font-style: normal;

}
@font-face {
  font-family: 'Poppins MediumItalic';
  src: url('fonts/Poppins-MediumItalic.woff2') format('woff2'),
        url('fonts/Poppins-MediumItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  
  }
  @font-face {
    font-family: 'Roboto Regular';
    src: 
          url('fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    
    }
  @font-face {
    font-family: 'Roboto Medium';
    src: 
          url('fonts/Roboto-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    
    }
  @font-face {
    font-family: 'Roboto Bold';
    src: 
          url('fonts/Roboto-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    
    }