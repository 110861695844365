.root {
  align-items: center;
  display: flex;
  height: 32px;
}

.filetype {
  display: flex;
}

.label {
  padding-inline-start: 8px;
}
.wexternalnode {
  padding: 6px;
  padding-left: 15px;
  cursor: move;
  /* border-bottom: 1px solid #d9d9d9; */
}
.wexternalnode:hover {
  background-color: var(--bs-primary-light);
}
.nodeDisabled {
  background: var(--bs-gray);
}