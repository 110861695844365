// custom scss
.img-auto {
  object-fit: contain !important;
}
.img-cover {
  object-fit: cover !important;
}
.form-control:disabled {
  color: var(--bs-gray-900);
  background-color: var(--bs-gray-100);
}
.form-control {
  min-height: $input-height-sm;
  padding: $input-padding-y-sm $input-padding-x-sm;
  @include font-size($input-font-size-sm);
  @include border-radius($input-border-radius-sm);

  &::file-selector-button {
    padding: $input-padding-y-sm $input-padding-x-sm;
    margin: (-$input-padding-y-sm) (-$input-padding-x-sm);
    margin-inline-end: $input-padding-x-sm;
  }
}
.bg-rm {
  background: transparent !important;
}
.d-none-after {
  &::after {
    display: none !important;
  }
}
.hide-dropdown-after {
  &.dropdown-toggle::after {
    display: none;
  }
}
.accordion-head-nocss {
  button {
    padding: 0;
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    &::after {
      display: none;
    }
    i {
      transition: all 300ms;
    }
  }
}
.accordion-head-start {
  button {
    align-items: start !important;
    background-color: #fff !important;
  }
}
.svg-custom {
  svg {
    padding: 1.35px;
  }
}
.menu {
  .menu-item > &,
  .menu-accordion > &,
  .show > .menu-link {
    font-weight: bold;
  }
}
.rounded-5 {
  border-radius: 2rem;
}
.table:not(.table-bordered) tfoot tr:last-child, .table:not(.table-bordered) tbody tr:last-child {
  border-bottom: 0 !important;
}
.table {
  tbody {
    /* tr:last-child {
      border-bottom: 0 !important;
    } */
    td {
      vertical-align: middle !important;
    }
  }
  .text-black {
    color: #3f4254 !important;
  }
}
.lh-30px {
  line-height: 30px !important;
}
.d-row-hover {
  .d-cell-hover {
    visibility: hidden;
  }
  &:hover {
    .d-cell-hover {
      visibility: visible;
    }
  }
}
// layout
.view-password {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 1;
}
.select-custom {
  padding: 0 !important;
  &.select-control-solid {
    .select__control {
      background: #f9f9f9;
    }
  }
  .select__control {
    background: transparent;
    height: auto;
    min-height: calc(1.5em + 1.1rem + 2px);
    font-size: 0.95rem;
    border-radius: 0.425rem;
    border: 1px solid #fff;
    opacity: 1;
  }
  .select__single-value--is-disabled {
    opacity: 1;
    color: #000;
  }
  .css-1p25tiz-indicatorContainer,
  .css-c4ei7m-indicatorContainer {
    padding: 0 5px;
  }
}
.form-error {
  border: 1px solid #ff5454 !important;
  border-radius: 0.425rem;
}
.popup-in-popup {
  background: rgb(0 0 0 / 20%);
}
.bg-auth {
  background: url("../../../../public/media/images/back-login.png") no-repeat;
  background-size: cover;
  background-position: bottom;
}
.svg-custom {
  svg {
    padding: 1.35px;
  }
}
.custom-select-table {
  .select__control--is-disabled {
    background: #fff !important;
    opacity: 1 !important;
  }
  .select__single-value {
    white-space: normal;
  }
  .select__single-value--is-disabled {
    opacity: 1 !important;
  }

  .select__indicators {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    z-index: 1;
  }

  .select__value-container {
    padding: 0;
    text-align: left;
  }

  .select__dropdown-indicator {
    display: none;
  }

  .select__clear-indicator {
    padding: 0;
    opacity: 0;
    display: none;
    background: #f2f5f8;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
  }

  .select__control {
    height: 30px;
    min-height: auto;
    border: 0;
    border-radius: 0;
  }

  .select__single-value {
    font-size: 1rem;
  }

  .select__menu-list {
    .select__option {
      font-size: 0.8rem !important;
    }
  }
  .input-form {
    padding: 0 3px;
  }
}

.wrap-action-row {
  position: relative;

  .action-row-table {
    opacity: 0;
    position: absolute;
    width: 30px;
    height: 100%;
    // z-index: 1;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .custom-select-table {
    .select__indicators {
      right: 25px;
      z-index: 0;
    }
  }
  &:hover {
    .action-row-table {
      opacity: 1;
      // position: relative;
      z-index: 2;
    }

    .custom-select-table {
      .select__indicator {
        right: 25px;
      }
      .select__clear-indicator {
        opacity: 1;
        display: flex;
      }
    }
  }
}
.custom-select-table {
  .select__menu-list,
  .select__menu-portal {
    min-width: 100px;
    text-align: left;
  }
  .select__input-container {
    cursor: cell;
    height: 100%;
  }
}
.table-invoice {
  &.select-more {
    .wrap-checkinvoice {
      display: table-cell;
    }
  }

  .wrap-checkinvoice {
    display: none;
  }
}
.data-grid-container .data-grid .cell {
  > input.data-editor {
    outline: none !important;
    border: 0;
    height: 100%;
    text-align: left;
  }
  &.text-center > input.data-editor {
    text-align: center;
  }
}
td {
  > .value-viewer {
    // text-align: center;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 2px;
    height: 100%;
    text-align: left;
  }
  &.text-center {
    > .value-viewer {
      justify-content: center;
      text-align: center;
    }
  }
}
.data-grid-container .data-grid .cell.read-only {
  background: #fff !important;
  color: inherit !important;
}
.modal-custom-form {
  .modal-body {
    background: #eff2f5;
  }
}

.table-manual {
  td,
  th {
    border: 1px solid #c3c3c3 !important;
    padding: 3px;
    vertical-align: middle;
    &.form-error {
      .value-viewer {
        border: 1px solid #ff5454 !important;
      }
    }
  }
  .hidden-add-row {
    tbody tr:last-child {
      display: none;
    }
  }
}
.h-el-after {
  &.dropdown-toggle::after {
    display: none;
  }
}
.data-grid-container .data-grid .cell {
  height: 30px !important;
}
.card-hover-info {
  .active {
    background: rgba(114, 57, 234, 0.8) !important;
    background-color: rgba(114, 57, 234, 0.8) !important;
    color: white;
  }
  &:hover {
    background: rgba(114, 57, 234, 0.8) !important;
    background-color: rgba(114, 57, 234, 0.8) !important;
    color: white;
  }
}
.events-none {
  pointer-events: none;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  color: rgba(57, 57, 57, 0.4) !important;
}
.disabled {
  pointer-events: none;
}
.border-black {
  border-color: #000 !important;
}
.table-print {
  thead {
    th {
      text-align: center;
      vertical-align: middle;
    }
  }

  td,
  th {
    border: 1px solid #000;
    padding: 2px;
    vertical-align: middle;
  }

  .border-0 {
    border: 0 !important;
  }
}

.print-pdf {
  .card {
    box-shadow: none;
    color: #000;
  }

  font-family: "Times New Roman", Times, serif;
  height: 100vh;
  background: #fff;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;

  .footer-pdf {
    background: #fff;
    border-bottom: 1px solid #fff;
    display: table-footer-group;
    height: 24px;
    // padding-right: 13px;
    page-break-after: always;
    position: absolute;
    right: 15px;
    left: 15px;
    top: 98.7vh;
    width: 97%;
    z-index: 2;
  }

  .footer-pdf.last {
    border-bottom: 0;
    height: 24px !important;
  }

  .footer-pdf.mid {
    border-bottom: 0;
    height: 35px;
  }

  .border {
    border-color: #000 !important;
  }
}
.hover-approval {
  position: relative;
  .in-approval {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    position: absolute;
    top: 37px;
    right: 7%;
    z-index: 1;
    &::after {
      color: var(--bs-warning-light) !important;
      content: "\f5e3";
      vertical-align: -0.125em;
      fill: currentcolor;
      display: inline-block;
      font-family: bootstrap-icons !important;
      font-style: normal;
      font-weight: 400 !important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: -0.125em;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      top: -9px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      z-index: 1;
      color: #fff;
    }
    &.custom-dasboard {
      position: inherit;
      .wrap-action-approval {
        display: flex;
        gap: 1.5rem;
        .menu-item {
          min-width: 92px;
          &.border {
            background: #50cd89;
          }
          background: #f1416c;
          border-radius: 6px;
          border: 0 !important;
          .menu-link {
            color: #fff !important;
          }
        }
      }
    }
  }
  &:hover .in-approval {
    opacity: 1;
    visibility: visible;
  }
}
.custom-loading {
  overflow: hidden;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #38c1f1;
  display: block;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 20px auto 0;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.h-100vh {
  height: 100vh;
}
.font-normal {
  font-style: normal !important;
}
.menu-link {
  &:hover {
    .text-black {
      color: var(--bs-primary) !important;
    }
  }
}
.hover-bold {
  &:hover,
  &i:before {
    font-weight: 600 !important;
  }
}
.custom-btn-add-file {
  margin-top: -75px;
}
.min-w-120px {
  min-width: 120px !important;
}
.min-w-110px {
  min-width: 110px !important;
}
.form-check-custom {
  gap: 0.5rem;
}
.form-switch .form-check-input {
  width: 3.75rem;
}
.wrapper-tree {
  min-height: 100px;
  &.empty {
    div {
      padding: 20px;
    }
    position: relative;
    .drag-noti {
      position: absolute;
      border-radius: 5px;
      background: #f5f8fa;
      border: 1px dashed #ccc;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}
.table-tab-move {
  thead {
    display: none;
  }
  .wrap-toolbar {
    padding-right: 5px;
    margin-bottom: 0 !important;
    > .position-relative {
      width: 100%;
    }
    input {
      border: 0;
      border-bottom: 1px solid #181c32;
      border-radius: 0;
    }
  }
  td {
    padding: 0;
  }
}
.wrap-content-sticky {
  position: sticky;
  top: 11vh;
  left: 0;
}
.value-viewer {
  cursor: cell;
}
.bullet-custom {
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 10px;
  height: auto;
}
.z-index-99 {
  z-index: 99;
}
.z-index-9 {
  z-index: 9;
}
.table-tab-move {
  .page-item .page-link {
    min-width: 20px;
    height: 20px;
    padding: 0;
    font-size: 10px;
  }
}
.bg-fnb {
  background-color: #afafaf !important;
}
.overflow-y-auto {
  overflow-y: auto;
}
.menu-item.menu-accordion.show {
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  padding-bottom: 0;
}
[data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item .menu-link.active {
  background-color: #ffffff;
}
[data-kt-app-layout="dark-sidebar"] .app-sidebar {
  ::-webkit-scrollbar-thumb {
    background: #83a6bb56 !important;
  }
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  .menu .menu-item {
    &:last-child {
      padding-bottom: 0;
    }
    .menu-sub {
      margin-left: 0;
      .menu-link {
        &:hover {
          background-color: #d3efff !important;
          border-radius: 0;
        }
        &.without-sub {
          padding-left: 3rem;
        }
      }
    }
    // .menu-link.active {
    //   background-color: #d3efff!important;
    // }

    &.show {
      .menu-link {
        .menu-arrow:after {
          background-color: #000 !important;
        }
        &:hover .menu-title,
        .menu-title,
        .svg-icon,
        i {
          color: #000 !important;
        }
        &:last-child {
          padding-bottom: 8px;
        }
        &.active {
          margin-right: 0;
          background-color: #d3efff !important;
          border-radius: 0;
        }
      }
    }
  }
}

.aside-dark .menu .menu-item.show > .menu-link {
  transition:
    color 0.2s ease,
    background-color 0.2s ease;
  background-color: #ffffff;
  color: #34c1f1;
}
.line-after {
  >div.label-item {
    position: relative;
    display: inline-block;
    height: 100%;
    &::after {
      content: "";
      position: absolute;
      top: -2px;
      right: 0;
      bottom: -2px;
      width: 1px;
      background: #000;
      z-index: 1;
    }
  }
}
.grid-auto-flow-column {
  grid-auto-flow: column!important;
  grid-auto-columns: max-content!important;
}
.custom-arrow-for-select {
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
}
.custom-search-select {
  .select__control.select__control--is-focused {
    width: 70px;
    transform: translateX(-0px);
  }
  
  &.select-w-200px {
    .Reactvirtualized__Grid__innerscrollcontainer > div {
      height: auto!important;
    }
    .select__control.select__control--is-focused {
      width: 200px;
      transform: translateX(-0px);
    }
  }
  &.select-w-400px {
    .Reactvirtualized__Grid__innerscrollcontainer {
      height: auto!important;
    }
    .select__control.select__control--is-focused {
      width: 400px;
      transform: translateX(-0px);
    }
  }
}
/* Chrome, Safari, Edge, Opera */
input.hide-appearance {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.wrapper-forcus-times {
  &.type1 {
    input {
      @extend .border-secondary
    }
    div:nth-child(2),
    div:nth-child(3) {
      display: none !important;
    }
  }
  &.type2 {
    input {
      @extend .border-secondary
    }
    div:nth-child(1),
    div:nth-child(2) {
      display: none !important;
    }
  }
}
.wrap-or-login {
  .line-1 {
    width: 47%;
    border-bottom: 1px solid #181c32;
    height: 2px;
  }
}
.loading-abs {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
}
.one-row-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.object-contant {
  object-fit: contain;
}
.select-chart {
  text-align: end;
  .select__control {
    border: 0;
    background-color: transparent;
    min-height: 0!important;
    cursor: pointer;
  }
  .select__dropdown-indicator {
    background-color: transparent;
  }
  .select__value-container {
    padding: 0;
  }
}
.apexcharts-toolbar {
  display: none!important;
}
.card-body .apexcharts-xaxistooltip.apexcharts-theme-light {
  background: #fff!important;
}
.chart-rei {
  .wrap-pagi {
    // display: block;
    flex-direction: column-reverse;
    >div{
      width: 100%!important;
    }
    .col-md-7 {
      width: 100%!important;
      justify-content: center!important;
    }
    .col-md-5 {
      width: 100%!important;
      justify-content: center!important;
      margin-top: 15px;
    }
  }
}
.custom-select-cell {
  .css-gwukah-control, .css-7rhanm-control, .css-13gkv7c-control, .css-ed9stw-control {
    min-height: 30px!important;
  }
  .css-1u9des2-indicatorSeparator, .css-1f43avz-a11yText-A11yText {
    display: none;
  }
  .css-13cymwt-control {
    border: 0;
    outline: 0;
  }
  .css-t3ipsp-control {
    border: 0;
    outline: 0;
    &:hover {
      border: 0;
      outline: 0;
      box-shadow: none;
    }
  }
  .css-1xc3v61-indicatorContainer, .css-15lsz6c-indicatorContainer {
    padding: 0;
  }
}
.page-break-avoid {
  page-break-inside: avoid;
}
.tr-cell-custom {
  .clear-row-cell {
    cursor: pointer;
    display: none;
  }
  &:hover {
    .stt-number {
      display: none;
    }
    .clear-row-cell {
      display: block;
    }
  }
  
}
.custom-cell {
  position: relative;
  
  .event-cell-popup {
    visibility: hidden;
    position: absolute;
    right: 0;
    z-index: 1;
    cursor: pointer;
    padding: 5px;
    background: #fff;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.selected {
    .event-cell-popup {
      visibility: visible;
    }
    .value-viewer,.data-editor {
      padding-right: 25px;
    }
  }
}
.text-transform-capitalize {
  text-transform: capitalize;
}
.gap-1-custom {
  gap: 0.2rem !important;
}

.form-check-label{
  color: #000 !important;
}